import Vue from 'vue'
import App from './App.vue'
import './assets/css/public.css'
import WujieVue from 'wujie-vue2'
import hostMap from './hostMap'
import credentialsFetch from './fetch'
import lifecycles from './lifecycle'
import plugins from './plugin'
import router from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueCompositionAPI from '@vue/composition-api'
import piniaPersist from 'pinia-plugin-persist'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

if (window.require) {
    const electron = window.require('electron') // 引用electron
    const fs = window.require('fs') // 引用Node模块fs
    const remote = window.require('@electron/remote') // 引用remote模块用于通信、打开文件弹框等
    Vue.prototype.$electron = electron
    Vue.prototype.$fs = fs
    Vue.prototype.$remote = remote
}

Vue.config.productionTip = false

const pinia = createPinia()
pinia.use(piniaPersist)
Vue.use(PiniaVuePlugin)
Vue.use(VueCompositionAPI)

Vue.use(ElementUI)

// eslint-disable-next-line no-unused-vars
const { setupApp, preloadApp, bus } = WujieVue
Vue.use(WujieVue)

// bus.$on("click", (msg) => window.alert(msg))

// 在 xxx-sub 路由下子应用将激活路由同步给主应用，主应用跳转对应路由高亮菜单栏
// bus.$on("sub-route-change", (name, path) => {
//     console.log(name, path)
//     const mainName = `${name}-sub`
//     const mainPath = `/${name}-sub${path}`
//     const currentName = router.currentRoute.name
//     const currentPath = router.currentRoute.path
//     if (mainName === currentName && mainPath !== currentPath) {
//         router.push({ path: mainPath })
//     }
// })

const props = {
    jump: (name) => {
        router.push({ name })
    },
}

console.log('VUE_APP_ENV', process.env.VUE_APP_ENV)

/**
 * 大部分业务无需设置 attrs
 * 此处修正 iframe 的 src，是防止github pages csp报错
 * 因为默认是只有 host+port，没有携带路径
 */
// const attrs = process.env.VUE_APP_ENV === 'development' ? {} : { src: hostMap("//localhost:8888/") }

setupApp({
    name: "sfl",
    url: hostMap("//localhost:8000/"),
    // attrs,
    exec: true,
    alive: true, // 保活模式
    sync: false,
    props,
    fetch: credentialsFetch,
    plugins,
    // prefix: { "prefix-dialog": "/dialog", "prefix-location": "/location" },
    // degrade, // 子应用采用降级iframe方案
    ...lifecycles,
})

setupApp({
    name: "sre",
    url: hostMap("//localhost:8100/"),
    // attrs,
    exec: true,
    alive: true, // 保活模式
    sync: true,
    props,
    fetch: credentialsFetch,
    plugins,
    // prefix: { "prefix-dialog": "/dialog", "prefix-location": "/location" },
    // degrade, // 子应用采用降级iframe方案
    ...lifecycles,
})

setupApp({
    name: "sbc",
    url: hostMap("//localhost:8200/"),
    // attrs,
    exec: true,
    alive: true, // 保活模式
    sync: true,
    props,
    fetch: credentialsFetch,
    plugins,
    // prefix: { "prefix-dialog": "/dialog", "prefix-location": "/location" },
    // degrade, // 子应用采用降级iframe方案
    ...lifecycles,
})

setupApp({
    name: "scs",
    url: hostMap("//localhost:8300/"),
    // attrs,
    exec: true,
    alive: true, // 保活模式
    sync: true,
    props,
    fetch: credentialsFetch,
    plugins,
    // prefix: { "prefix-dialog": "/dialog", "prefix-location": "/location" },
    // degrade, // 子应用采用降级iframe方案
    ...lifecycles,
})

// 预加载
// preloadApp({
//     name: "sfl",
// })

new Vue({
    router,
    pinia,
    render: h => h(App),
}).$mount('#app')
