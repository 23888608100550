<template>
    <div class="container">
        <div class="header">
            <div class="a-tab" :class="{ 'base': item.appId == 0, 'active': item.appId == activeAppId }" v-for="(item, index) in tabList" :key="item.appId" @click="activeAppId = item.appId">
                <router-link class="a-link" :to="item.appPath" v-if="item.appId == 0"><i class="el-icon-house"></i></router-link>
                <router-link class="a-link" :to="item.appPath" v-else>{{ item.appName }}</router-link>
                <a class="close" @click="handleClose(index)" v-if="item.appId != 0"><i class="el-icon-close"></i></a>
            </div>
        </div>
        <div class="main">
            <router-view />
        </div>
        <div class="footer"></div>
    </div>
</template>

<script>
import wujieVue from 'wujie-vue2'
import { mapState, mapActions } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
export default {
    data() {
        return {
            tabList: [
                { appId: 0, appName: '产品销售大模型中控系统', appPath: '/' },
                // { appId: 1, appName: '产品特征库', appPath: '/sfl' },
            ],
            activeAppId: 0,
        }
    },
    computed: {
        ...mapState(useAppStore, ['tabs'])
    },
    mounted() {
        let tabs = this.tabs?.length ? this.tabs : null
        if (tabs) {
            this.tabList = tabs
        } else {
            this.setTabs(this.tabList)
        }
        let routePath = this.$route.path
        let current = this.tabList.filter(item => item.appPath == routePath)
        this.activeAppId = current[0].appId

        wujieVue.bus.$on('addTab', (val) => {
            // console.log('addTab', val)
            this.activeAppId = val.appId // 设置选中
            let flag = this.tabList.some(item => item.appId == val.appId)
            if (!flag) { // 判断appId是否存在于tabList数据中 不存在需要添加到tabList
                this.tabList.push(val)
                this.setTabs(this.tabList)
            }
        })
        wujieVue.bus.$on('appLogout', () => {
            console.log('appLogout')
            this.setToken(null)
            this.setUsers(null)
            this.setTabs(null)
            this.setMenus([])
            // this.$router.push({ path: '/login' })
            window.location.reload() // 直接刷新页面 可以使保活模式的子应用销毁重建
        })
        wujieVue.bus.$on('subAppLogout', (val) => {
            console.log('subAppLogout', val)
            this.setTabs(null)
            this.setMenus([])
            // this.$router.push({ path: '/login' })
            window.location.reload() // 直接刷新页面 可以使保活模式的子应用销毁重建
        })
    },
    methods: {
        ...mapActions(useUserStore, ['setToken', 'setUsers']),
        ...mapActions(useAppStore, ['setTabs', 'setMenus']),
        // 点击tab关闭
        handleClose(index) {
            // console.log(index)
            this.tabList.splice(index, 1)
            this.$nextTick(() => {
                this.setTabs(this.tabList)
                let path = '/'
                let appId = 0
                if (this.tabList.length > 1) {
                    path = this.tabList[index - 1].appPath
                    appId = this.tabList[index - 1].appId
                }
                this.activeAppId = appId
                this.$router.push({ path })
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .container {
        .header {
            width: 100%;
            height: 40px;
            background: #F4F6FB;
            border-bottom: 1px solid #ddd;
            .a-tab {
                display: inline-block;
                width: 180px;
                line-height: 40px;
                font-size: 14px;
                color: #666;
                border-right: 1px solid #ddd;
                position: relative;
                &:first-child {
                    border-left: 1px solid #ddd;
                }
                &.base {
                    width: 52px;
                    text-align: center;
                    .a-link {
                        padding-left: 0;
                    }
                }
                &.active {
                    z-index: 1;
                    border-bottom: 1px solid #fff;
                    border-radius: 0 8px 0 0;
                }
                &.active, &:hover {
                    background: #fff;
                    // .a-link {
                    //     color: #409eff;
                    // }
                }
                .a-link {
                    display: block;
                    padding-left: 20px;
                    height: 100%;
                    color: #666;
                    transition: all .2s;
                }
                .close {
                    cursor: pointer;
                    padding: 0 2px;
                    position: absolute;
                    top: 0;
                    right: 10px;
                    z-index: 1;
                    color: #666;
                    transition: all .2s;
                    &:hover {
                        color: #409eff;
                    }
                }
            }
        }
    }
</style>